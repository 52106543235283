import React, { ChangeEvent, InputHTMLAttributes, useState } from "react"
import { InputBase, LabelStyled, InputWrapper, InputContainer } from "./Input.styles"
import { cx } from "class-variance-authority"
import Typography from "../../../typography"

interface Props {
  label?: string
  error?: string
  className?: string
  required?: boolean
  type?: "text" | "number" | "textarea" | "email" | "date"
  value: string | number | undefined
  name: string
  onChange: any
  readOnly?: boolean
  button?: React.ReactElement
  onKeyPress?: any
}
const Input = ({
  label,
  onChange,
  type = "text",
  value,
  name,
  error,
  className,
  required,
  readOnly,
  button,
  onKeyPress,

  ...restProps
}: Props) => {
  const [focused, setFocused] = useState(false)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (value !== "") {
      setFocused(true)
    } else {
      setFocused(false)
    }
    if (onChange) {
      onChange(e.target.value)
    }
  }

  return (
    <InputContainer className={className}>
      <InputWrapper>
        <InputBase
          name={name}
          type={type}
          value={value}
          onChange={handleInputChange}
          $error={!!error}
          onKeyPress={onKeyPress}
          {...restProps}
          onFocus={() => setFocused(true)}
          onBlur={() => !value && setFocused(false)}
          readOnly={readOnly}
        />
        <LabelStyled $focused={!!(focused || value || value === 0)} htmlFor={name} $error={!!error}>
          {label}
        </LabelStyled>
      </InputWrapper>

      {error && (
        <Typography type="small" className="pl-4 pt-1 text-error-100">
          {error}
        </Typography>
      )}
    </InputContainer>
  )
}

export default Input
