import { Transition } from "@headlessui/react"
import { cx } from "class-variance-authority"
import Image from "next/image"
import { Fragment } from "react"

const Xclear = ({ show, clear }: any) => {
  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-x-4 	"
      enterTo="opacity-100 -translate-x-0 "
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 -translate-x-0 	"
      leaveTo="opacity-0 translate-x-2 "
    >
      <button
        className={cx("group absolute right-2 top-1/2 -translate-y-1/2 transform bg-white")}
        onClick={e => {
          e.stopPropagation()
          clear()
        }}
      >
        <div
          className={
            "flex h-6 w-6 items-center justify-center rounded-full bg-white p-[5px] transition-all hover:bg-[#EEEEEE]"
          }
        >
          <Image src="/images/close.svg" width={14} height={14} alt="close" />
        </div>
      </button>
    </Transition>
  )
}

export default Xclear
