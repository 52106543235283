import { Keywords } from "./../../types/search"
import { useQuery } from "@tanstack/react-query"
import LandingService from "src/server/services/LandingService"
import { CacheTime } from "../../utils/configs/apiCacheTime"

interface Params {
  keyword: string
}

const useKeywordSearch = (params: Params) => {
  const keywordsData = useQuery<{
    keywords: string[]
    company_keywords: {
      company_id: number
      company_title: string
      has_logo: 1
    }[]
  }>({
    queryKey: ["keywords", params?.keyword],
    queryFn: () => getKeywordsData(params),
    staleTime: CacheTime.MAIN_PAGE
  })

  const keywords = keywordsData.data
  const isLoading = keywordsData.isLoading

  return { keywordSearchData: keywords, isLoading }
}

export const getKeywordsData = async (params: Params) => {
  if(!params || !params.keyword) return null

  try {
    const response: any = await LandingService.getKeywords(params)

    return response.data
  } catch (e: any) {
    console.error(e.data)
    throw e
  }
}

export default useKeywordSearch
