import { useTranslation } from "next-i18next"

export const degrees = [
  "ბაკალავრი",
  "მაგისტრი",
  "კურსდამთავრებული",
  "დოქტორი",
  "აკადემიკოსი",
  "სტუდენტი",
  "სპეციალისტი",
  "პროფესორი",
  "რეზიდენტი",
  "ასპირანტი",
  "სხვა"
]

export const degreesEn = [
  "bachelor",
  "master",
  "course_graduate",
  "doctorate",
  "academician",
  "student",
  "specialist",
  "professor",
  "resident",
  "aspirant",
  "without_education"
]

export const defaultCVFormData = {
  name: "გიორგი",
  surname: "გოგიტაშვილი",
  publicAccount: true,
  birthDate: "",
  sex: "",
  current_position: false,
  profession: "",
  mobileNumber: "",
  email: "gukanozadze@gmail.com",
  country: "",
  city: "",
  postalCode: "",
  address: "",
  university: "",
  quality: "",
  faculty: "",
  start_date: "",
  end_date: "",

  languages: [],
  skills: [
    "UI/UX Designer",
    "Frontend Developer",
    "Business Manager",
    "Photoshop",
    "UI/UX Designer",
    "Frontend Developer",
    "Business Manager",
    "Photoshop"
  ],
  education: [],
  experiences: []
}

// DO NOT TOUCH IT
export const optionsExpLevel = [
  { id: 1, label: "INTERN", value: "junior" },
  { id: 2, label: "MIDDLE", value: "mid-level" },
  { id: 3, label: "SENIOR_SPECIALIST", value: "senior" },
  { id: 4, label: "LEAD_SPECIALIST", value: "head" }
]

// DO NOT TOUCH IT
export const optionsHireType = [
  { id: 1, label: "URGENT", value: "urgent" },
  { id: 2, label: "CONTRACT", value: "contract" },
  { id: 3, label: "INTERNSHIP", value: "intern" },
  { id: 4, label: "SEASONAL", value: "seasonal" },
  { id: 5, label: "FREELANCE", value: "freelance" }
]

// DO NOT TOUCH IT
export const optionsEmploymentType = [
  { id: 1, label: "FULL_TIME", value: "full_time" },
  { id: 2, label: "PART_TIME", value: "part_time" },
  { id: 3, label: "HOURLY_TIME", value: "hourly" },
  { id: 4, label: "SHIFTS", value: "shifts" }
]
export const ConvertEmploymentTypeValueToGeorgian = (
  value: string | undefined | null,
  t: (key: string) => string,
  appendText?: string
) => {
  if (!value) return ""

  let label = optionsEmploymentType.find(item => item.value === value)?.label || ""
  label = t(label)

  return appendText ? `${appendText} ${label}` : label
}

// DO NOT TOUCH IT
export const optionsJobType: IOptionJobType[] = [
  { id: 1, label: "OFFICE", value: "on_site" },
  { id: 2, label: "REMOTE", value: "remote" },
  { id: 3, label: "HYBRID", value: "hybrid" },
  { id: 4, label: "FREE_SCHEDULE", value: "freelance" }
]
export const convertJobTypeValueToGeorgian = (value: string) => {
  return optionsJobType.find(item => item.value === value)?.label || ""
}

export interface IOptionJobType {
  id: number
  label: string
  value: string
}

// DO NOT TOUCH IT
export const optionsGender = [
  { id: 1, label: "მამრობითი", value: "male" },
  { id: 2, label: "მდედრობითი", value: "female" }
]

export const optionsEducationLevel = [
  { id: 1, label: "STUDENT", value: "student" },
  { id: 2, label: "BACHELOR", value: "bachelor" },
  { id: 3, label: "MASTER", value: "master" },
  { id: 5, label: "DOCTORATE", value: "doctorate" },
  { id: 6, label: "COURSE_GRADUATE", value: "course_graduate" },
  { id: 7, label: "PROFESSOR", value: "professor" },
  { id: 8, label: "RESIDENT", value: "resident" },
  { id: 9, label: "ASPIRANT", value: "aspirant" },
  { id: 10, label: "ACADEMICIAN", value: "academician" },
  { id: 11, label: "SPECIALIST", value: "specialist" },
  { id: 12, label: "WITHOUT_EDUCATION", value: "without_education" }
]

// DO NOT TOUCH IT
export const optionsSkills = [
  { id: 1, label: "Frontend", value: "Frontend" },
  { id: 2, label: "Backend", value: "Backend" },
  { id: 3, label: "Photoshop", value: "Photoshop" },
  { id: 4, label: "Assistant", value: "Assistant" },
  { id: 5, label: "Web3", value: "Web3" },
  { id: 6, label: "Min Android", value: "Min Android" },
  { id: 7, label: "IOS Developer", value: "IOS Developer" },
  { id: 8, label: "Developer", value: "Developer" },
  { id: 9, label: "UX/UI Designer", value: "UX/UI Designer" },
  { id: 10, label: "Web Developer", value: "Web Developer" },
  { id: 11, label: "Devops", value: "Devops" },
  { id: 12, label: "Backend Developer", value: "Backend Developer" },
  { id: 13, label: "Software Architect", value: "Software Architect" }
]

// /api/public/vacancies params
export const vacancy_search_query_params = {
  keyowrds: [""],
  country_id: 5, // OR NULL
  salary_from: 5, // OR NULL
  salary_to: 10, // OR NULL
  experience_times: [1, 2, 3], // OR NULL
  experience_level: ["junior", "middle", "senior", "lead"], // OR NULL
  education: [
    "ბაკალავრი",
    "მაგისტრი",
    "კურსდამთავრებული",
    "დოქტორი",
    "აკადემიკოსი",
    "სტუდენტი",
    "სპეციალისტი",
    "პროფესორი",
    "რეზიდენტი",
    "ასპირანტი"
  ], // OR NULL
  hire_type: ["urgent", "contract", "intern", "seasonal", "freelance"], // OR NULL
  employment_type: ["full_time", "part_time", "hourly", "shifts"],
  industry_id: [1, 2] // OR NULL
}

export const driving_licenses = [
  "AM",
  "A",
  "B",
  "C",
  "D",
  "BE",
  "CE",
  "DE",
  "T",
  "S",
  "A1",
  "A2",
  "B1",
  "C1",
  "D1",
  "C1E",
  "D1E"
]
