import { NODE_ENV } from "src/env"

export const sendFiltersGoogleAnalytics = (queryParams: URLSearchParams) => {
  if (NODE_ENV === "production") {
    // Convert queryParams to an object, filter out empty values
    const filteredParams = Object.fromEntries(Array.from(queryParams.entries()).filter(([_, value]) => value))

    window.gtag("event", "search_filter", filteredParams)
  }
}
