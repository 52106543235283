import { Combobox, Transition } from "@headlessui/react"
import { cx } from "class-variance-authority"
import Image from "next/image"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import DropdownCheckbox from "src/views/components/common/form-controll/Checkbox/DropdownCheckbox"
import Typography from "src/views/components/typography"
import useIndex from "src/server/queryHooks/useIndex"
import { ComboboxNotFoundText } from "../SearchBox.styles"
import Xclear from "./Xclear"
import useVacancy from "src/server/queryHooks/useVacancy"
import { useTranslation } from "next-i18next"

interface Props {
  style_type: "vacancy" | "main"
  setSelectedLocationsParams: any
}

const LocationSearchDropdown = ({ style_type, setSelectedLocationsParams }: Props) => {
  const { t } = useTranslation("common")
  const { langSkillsCountriesQuery } = useVacancy()
  const { mainPageDataV2 } = useIndex()

  const router = useRouter()
  const [selectedLocations, setSelectedLocations] = useState<string[]>([])
  const [selectedLocationIds, setSelectedLocationIds] = useState<number[]>([])
  const countries = mainPageDataV2?.countries

  const clear = () => {
    setSelectedLocations([])

    if (router.pathname.includes("vacancy")) {
      router.push({
        pathname: router.pathname,
        query: { ...router.query, countries: "" }
      })
    }
  }

  useEffect(() => {
    const param = router.query.countries
    setSelectedLocationIds([])
    setSelectedLocations([])
    if (param && typeof param === "string") {
      const locationIds = param.split(",").map(id => parseInt(id.trim(), 10))
      const locationTitles: string[] = []
      countries?.forEach(country => {
        if (locationIds.includes(country.id)) locationTitles.push(country.title)

        if (country.children) {
          country.children.forEach(city => {
            if (locationIds.includes(city.id)) locationTitles.push(city.title)
          })
        }
      })
      setSelectedLocations(locationTitles)
      setSelectedLocationIds(locationIds)
    }
  }, [router.query.countries])

  useEffect(() => {
    setSelectedLocationsParams((prevState: any) => {
      return [...selectedLocationIds]
    })
  }, [selectedLocations, setSelectedLocationsParams])

  const handleChange = (event: any) => {
    if (router.pathname.includes("vacancy")) {
      const currentQuery = { ...router.query, countries: "" }

      if (event.length) currentQuery["countries"] = event.join(",")

      router.push({
        pathname: router.pathname,
        query: currentQuery
      })
    }
  }

  const [query, setQuery] = useState("")
  const [isAllSelected, setIsAllSelected] = useState(false)

  const filteredOptions =
    query === "" && countries
      ? countries
      : countries?.filter(country =>
          country.title.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, ""))
        )

  return (
    <Combobox value={selectedLocations} as="div" className={"relative hidden h-full flex-1 md:flex md:flex-1 location-dropdown"} multiple>
      <Combobox.Button
        className={"relative flex h-full w-full cursor-pointer items-center pl-4 pr-4 focus:outline-none"}
      >
        <Image src={"/images/place.svg"} width={24} height={24} className="mr-2" alt={"place"} />
        <Combobox.Input
          autoComplete="off"
          placeholder={t("LOCATION")}
          className={cx(
            " h-full w-full cursor-pointer bg-inherit py-1 text-2sm text-secondary-100 placeholder:font-tbcx-regular placeholder:text-2sm placeholder:text-secondary-50",
            style_type === "vacancy" && "border-neutral-40"
          )}
          displayValue={(options: string[]) => options.join(", ")}
          onChange={e => setQuery(e.target.value)}
        />
      </Combobox.Button>
      {/* X clear button */}
      <Xclear show={selectedLocations.length > 0} clear={clear} />

      <Transition
        as={"div"}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={cx("absolute z-10 mt-2.5", style_type === "main" ? "top-[72px] " : "top-[60px] ")}
        afterLeave={() => setQuery("")}
      >
        <Combobox.Options
          className={
            "-ml-28 max-h-[400px] w-[482px] overflow-auto rounded-[14px] border border-neutral-20 bg-white shadow-card-box"
          }
        >
          {filteredOptions?.length === 0 && query !== "" ? (
            <ComboboxNotFoundText className="p-6">{t("NOT_FOUND")}</ComboboxNotFoundText>
          ) : (
            <div className="p-2.5 ">
              {/* <div className="flex cursor-pointer items-center gap-2 px-4 py-3">
                <Image src="/images/place_primary.svg" width={24} height={24} alt="place" />
                <Typography type="text" className="text-primary-100">
                  ახლანდელი მდებარეობა
                </Typography>
              </div> */}
              <Combobox.Options className={"mt-0 border-neutral-20 pt-0"}>
                {filteredOptions?.map((country, idx) => (
                  <div key={country.title + idx}>
                    <div className="px-4 py-3">
                      <Typography type="text" weight="medium" className="text-secondary-70">
                        {country.title}
                      </Typography>
                    </div>
                    {country.title === "საქართველო" && (
                      <Combobox.Option
                        key={t("SELECT_ALL")}
                        value={t("SELECT_ALL")}
                        onClick={() => {
                          const isAnyCitySelected = country.children?.some(city =>
                            selectedLocations.includes(city.title)
                          )
                          if (isAnyCitySelected) {
                            // If any city is selected, deselect all cities of this country
                            setSelectedLocations(prevLocations =>
                              prevLocations.filter(
                                location =>
                                  !country.children?.map(city => city.title).includes(location) &&
                                  location != country.title
                              )
                            )
                            setSelectedLocationIds(prevLocations => [
                              ...prevLocations.filter(
                                location =>
                                  !country.children?.map(city => city.id).includes(location) && location != country.id
                              )
                            ])
                            handleChange(
                              selectedLocationIds.filter(
                                location =>
                                  !country.children?.map(city => city.id).includes(location) && location != country.id
                              )
                            )
                            setIsAllSelected(false)
                          } else {
                            // If no city is selected, select all cities of this country
                            setSelectedLocations(prevLocations => [
                              country.title,
                              ...prevLocations,
                              ...(country.children?.map(city => city.title) ?? [])
                            ])
                            setSelectedLocationIds(prevLocations => [
                              country.id,
                              ...prevLocations,
                              ...(country.children?.map(city => city.id) ?? [])
                            ])
                            handleChange([
                              ...selectedLocationIds,
                              ...(country.children?.map(city => city.id) ?? []),
                              country.id
                            ])
                            setIsAllSelected(true)
                          }
                        }}
                      >
                        {({ active, selected }) => (
                          <DropdownCheckbox
                            key={country.title}
                            name={t("SELECT_ALL")}
                            label={t("SELECT_ALL")}
                            selected={isAllSelected}
                            className="px-12"
                          />
                        )}
                      </Combobox.Option>
                    )}

                    {country.children?.map(city => (
                      <Combobox.Option
                        key={city.id}
                        value={city.title}
                        onClick={() => {
                          if (selectedLocations.includes(city.title)) {
                            setSelectedLocations(prevLocations =>
                              prevLocations.filter(location => location !== city.title)
                            )
                            setSelectedLocationIds(prevLocations =>
                              prevLocations.filter(location => location !== city.id)
                            )
                            handleChange([selectedLocationIds.filter(location => location !== city.id)])
                          } else {
                            setSelectedLocations(prevLocations => [...prevLocations, city.title])
                            setSelectedLocationIds(prevLocations => [...prevLocations, city.id])
                            handleChange([...selectedLocationIds, city.id])
                          }
                        }}
                      >
                        {({ active, selected }) => (
                          <DropdownCheckbox
                            key={city.title}
                            name={city.title}
                            label={city.title}
                            selected={selected}
                            className="px-12"
                          />
                        )}
                      </Combobox.Option>
                    ))}
                  </div>
                ))}
              </Combobox.Options>
            </div>
          )}
        </Combobox.Options>
      </Transition>
    </Combobox>
  )
}

export default LocationSearchDropdown
// {filteredOptions.map(country => (
//   <Disclosure key={country.id} defaultOpen={country.title === 'საქართველო'}>
//     {({ open }) => (
//       <>
//         <Disclosure.Button className='flex items-center gap-2 p-4'>
//           <Image
//             src={`/images/expand_more.svg`}
//             width={24}
//             height={24}
//             alt={`arrow`}
//             className={cx(
//               'translate transition-all duration-300 ease-in-out',
//               open ? '-rotate-180' : 'rotate-0'
//             )}
//           />
//           <Typography type='text' weight='medium'>
//             {country.title}
//           </Typography>
//         </Disclosure.Button>
//         <Disclosure.Panel className='text-gray-500' as='ul'>
//           {country.children &&
//             country.children.map(city => (
//               <Combobox.Option key={city.id} value={city.title} as='li'>
//                 {({ active, selected }) => (
//                   <CheckboxLabel
//                     key={city.title}
//                     name={city.title}
//                     label={city.title}
//                     selected={selected}
//                     className='px-12'
//                   />
//                 )}
//               </Combobox.Option>
//             ))}
//         </Disclosure.Panel>
//       </>
//     )}
//   </Disclosure>
// ))}
