import tw from "tailwind-styled-components"

interface SearchBoxLayout {
  style_type: "vacancy" | "main"
}

export const SearchBoxInterfaceBox = tw.div<SearchBoxLayout>`
${p => (p.style_type === "main" ? "border-primary-100" : "border-neutral-40")}
${p => (p.style_type === "main" ? "rounded-4xl" : "rounded-xl")}
${p => (p.style_type === "main" ? "border-[1.6px]" : "border-[1px]")}
${p => (p.style_type === "main" ? "h-14 md:h-[72px]" : "h-[60px]")}
${p => (p.style_type === "main" ? "" : "flex-1")}

flex justify-between relative w-full bg-white`

export const SearchBoxActionButtons = tw.div`gap-1.5 md:gap-3 flex items-center shrink-0 mr-2`
export const SearchBoxFilterButton = tw.button`relative cursor-pointer bg-neutral-20 p-[9px] rounded-full`
export const SearchBoxSearchButton = tw.button`cursor-pointer bg-primary-100 p-[12.81px] md:p-[16.81px] rounded-full `

// export const SearchComboBoxInput = tw.input`w-full h-full cursor-pointer bg-inherit py-1 text-2sm text-secondary-100 placeholder:font-tbcx-regular placeholder:text-2sm placeholder:text-secondary-50`
export const SearchComboBoxButton = tw.div`relative flex h-full w-full cursor-pointer items-center focus:outline-none`
export const SearchComboBoxOptions = tw.div`absolute z-10 top-0 left-0 mt-4 overflow-auto rounded-[14px] border border-neutral-20 bg-white shadow-card-box`

export const ComboboxNotFoundText = tw.div`p-2.5 cursor-default select-none text-gray-700`
